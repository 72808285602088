import { GridItem, TypographyV3 } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import type { MessageKey } from 'types/LocaleMessageKey';

import Image, { type ImageProps } from '@/app/components/Image';
import Motion from '@/app/components/Motion';

export interface LandingPageHeroImage extends ImageProps {
  quote?: MessageKey;
  name?: string;
}

export interface LandingPageHeroImagesProps {
  readonly images: [LandingPageHeroImage, LandingPageHeroImage];
}

const LandingPageHeroImages = ({
  images,
}: LandingPageHeroImagesProps): JSX.Element => {
  const t = useTranslations();

  return (
    <>
      <GridItem
        className="self-end pt-32 md:pt-80 lg:pt-0"
        colEnd={{ sm: 'main', md: 'main', lg: 'main' }}
        colStart={{ sm: 3, md: 5, lg: 16 }}
        rowEnd={{ sm: 4, lg: 3 }}
        rowStart={{ sm: 3, lg: 1 }}
      >
        <Motion
          className="relative aspect-3/2"
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 2, type: 'tween', delay: 0.75 }}
        >
          <Image
            {...images[0]}
            className="rounded-lg object-cover"
            data-cy="landing-page-hero-image-0"
            fill
            priority
            sizes="(max-width: 1023px) 80vw, 33vw"
          />
          {images[0].quote && (
            <blockquote className="absolute inset-x-4 bottom-4 hidden text-sandstone-200 md:block">
              <TypographyV3
                className="mb-0.5 before:content-[open-quote] after:content-[close-quote]"
                color="inherit"
              >
                {t(images[0].quote)}
              </TypographyV3>
              <footer>
                <TypographyV3 color="inherit" className="text-sm">
                  {images[0].name}
                </TypographyV3>
              </footer>
            </blockquote>
          )}
        </Motion>
      </GridItem>
      <GridItem
        className="mb-[45vw] self-end lg:mb-[17vw]"
        colEnd={{ sm: 6, md: 13, lg: 20, xl: 19 }}
        colStart={{ sm: 'main', lg: 14, xl: 14 }}
        rowEnd={{ sm: 4, lg: 3, xl: 3 }}
        rowStart={{ sm: 3, lg: 1, xl: 1 }}
      >
        <Motion
          className="relative aspect-square"
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 2, type: 'tween', delay: 0.5 }}
        >
          <Image
            {...images[1]}
            className="rounded-lg"
            data-cy="landing-page-hero-image-1"
            fill
            sizes="(max-width: 1023px) 45vw, 25vw"
            priority
          />
          {images[1].quote && (
            <blockquote className="absolute inset-x-4 bottom-4 hidden text-sandstone-200 md:block">
              <TypographyV3
                className="mb-0.5 before:content-[open-quote] after:content-[close-quote]"
                color="inherit"
              >
                {t(images[1].quote)}
              </TypographyV3>
              <footer>
                <TypographyV3 color="inherit" className="text-sm">
                  {images[1].name}
                </TypographyV3>
              </footer>
            </blockquote>
          )}
        </Motion>
      </GridItem>
    </>
  );
};

export default LandingPageHeroImages;
