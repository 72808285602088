import { cx } from 'class-variance-authority';
import type { MessageKey } from 'types/LocaleMessageKey';

import SectionHeading, {
  type SectionHeadingProps,
} from '@/app/components/SectionHeading';

export interface LandingPageHeroTitleProps
  extends Pick<
    SectionHeadingProps,
    'emProps' | 'indentation' | 'typographyProps'
  > {
  readonly content: MessageKey;
  readonly hasEyebrow: boolean;
}

export const getHeadingElement = (hasEyebrow: boolean): 'h1' | 'h2' =>
  hasEyebrow ? 'h2' : 'h1';

const LandingPageHeroTitle = ({
  content,
  emProps = {},
  hasEyebrow,
  indentation,
  typographyProps,
}: LandingPageHeroTitleProps): JSX.Element => {
  return (
    <SectionHeading
      animated
      className="max-w-full"
      content={content}
      emAnimationProps={{
        transition: { duration: 1.5, type: 'tween', delay: 0.5 },
      }}
      emProps={emProps}
      indentation={cx('pl-28 maxlg:pl-0', indentation)}
      multiline
      typographyProps={{
        height: 'tight',
        ...typographyProps,
        className: cx(
          'tracking-tighter lg:max-w-none text-center lg:text-left',
          typographyProps?.className,
        ),
        color: 'inherit',
        Element: getHeadingElement(hasEyebrow),
        variant: 'h1',
      }}
      wrapperAnimationProps={{
        transition: { duration: 1, type: 'tween', delay: 0.5 },
      }}
    />
  );
};

export default LandingPageHeroTitle;
