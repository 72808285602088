import { ButtonV3, type ButtonV3Props } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import type { MessageKey } from 'types/LocaleMessageKey';

export interface LandingPageHeroCtaProps
  extends Omit<ButtonV3Props, 'children'> {
  readonly fixed?: {
    sm: boolean;
    lg: boolean;
  };
  readonly hidden?: {
    sm: boolean;
    lg: boolean;
  };
  readonly label: MessageKey;
}

const LandingPageHeroCta = ({
  fixed = {
    sm: false,
    lg: false,
  },
  hidden = {
    sm: false,
    lg: false,
  },
  label,
  ...props
}: LandingPageHeroCtaProps): JSX.Element => {
  const t = useTranslations();

  return (
    <div
      className={cx('w-full text-center lg:text-left', {
        'fixed bottom-8 left-0': fixed.sm,
        'lg:relative lg:bottom-auto lg:left-auto': !fixed.lg,
        'lg:fixed lg:bottom-16': fixed.lg,
        hidden: hidden.sm,
        'lg:block': !hidden.lg,
        'lg:hidden': hidden.lg,
      })}
      data-cy="landing-page-hero-cta-wrapper"
    >
      <ButtonV3 {...props} data-cy="landing-page-hero-cta">
        {t(label)}
      </ButtonV3>
    </div>
  );
};

export default LandingPageHeroCta;
