import { TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import type { ComponentPropsWithoutRef } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

export interface LandingPageHeroDescriptionProps
  extends ComponentPropsWithoutRef<'p'> {
  readonly content: MessageKey;
  readonly hasBackgroundImage: boolean;
}

const LandingPageHeroDescription = ({
  className,
  content,
  hasBackgroundImage,
}: LandingPageHeroDescriptionProps): JSX.Element => {
  const t = useTranslations();

  return (
    <TypographyV3
      className={cx(
        'relative text-center text-base md:text-body-lg lg:text-left',
        {
          'text-center lg:text-left': hasBackgroundImage,
        },
        className,
      )}
      color="inherit"
      data-cy="landing-page-hero-description"
    >
      {t.rich(content)}
    </TypographyV3>
  );
};

export default LandingPageHeroDescription;
