import { TypographyV3 } from '@jouzen/ecom-components';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import type { MessageKey } from 'types/LocaleMessageKey';

import Motion from '@/app/components/Motion';

export interface LandingPageHeroEyebrowProps {
  readonly className?: string;
  readonly content: MessageKey;
}

const LandingPageHeroEyebrow = ({
  className,
  content,
}: LandingPageHeroEyebrowProps): JSX.Element => {
  const t = useTranslations();

  return (
    <Motion
      initial={{ opacity: 0 }}
      transition={{ duration: 1, type: 'tween', delay: 0.25 }}
      viewport={{ once: true }}
      whileInView={{ opacity: 1 }}
    >
      <TypographyV3
        color="inherit"
        className={cx(
          'text-center uppercase tracking-wide lg:block lg:text-left lg:text-base',
          className,
        )}
        data-cy="landing-page-hero-eyebrow"
        Element="h1"
        variant="body-small"
        weight="bold"
      >
        {t(content)}
      </TypographyV3>
    </Motion>
  );
};

export default LandingPageHeroEyebrow;
