import { IconTagV3 } from '@jouzen/ecom-components';
import { useTranslations } from 'next-intl';
import type { ReactNode } from 'react';
import type { MessageKey } from 'types/LocaleMessageKey';

import Motion from '@/app/components/Motion';

export interface IconTagProps {
  readonly iconTagText: MessageKey;
  readonly icon: ReactNode;
}

const LandingPageHeroIconTag = ({
  iconTagText,
  icon,
}: IconTagProps): JSX.Element => {
  const t = useTranslations();

  return (
    <Motion
      initial={{ filter: 'blur(5px)', opacity: 0 }}
      transition={{ duration: 1.5, type: 'tween', delay: 0.5 }}
      viewport={{ once: true }}
      whileInView={{ filter: 'blur(0px)', opacity: 1 }}
    >
      <IconTagV3 icon={icon} iconTagText={t(iconTagText)} />
    </Motion>
  );
};

export default LandingPageHeroIconTag;
